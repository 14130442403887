<template>
    <VCard elevation="0"  class="cardrow  rounded-xl">
        <div class="card">
                <div>
                    <div class="numbers">{{valueNum}}</div>
                    <div class="cardName">{{title}}</div>
                </div>
                <div class="iconBx">
                    <v-icon v-text="icon" size="55" color="grey"></v-icon>
                </div>
            </div>
    </VCard>
    
</template>

<script>
export default {
    props: {
        title: String,
        valueNum: String,
        icon: String
    }

}
</script>

<style scoped>
.cardrow
{
    border-radius: 20px;
    padding: 20px;
}
.card {
background: var(--white);
padding: 30px;
border-radius: 20px;
display: flex;
justify-content: space-between;
cursor: pointer;
box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}

.card .numbers {

font-weight: 800;
font-size: 2.2rem;
color: var(--blue);
}

.card .cardName {
color: var(--black2);
font-size: 1.5rem;
margin-top: 5px;
}

.card .iconBx {
font-size: 3.5rem;
color: var(--black2);
}

.card:hover {
background: var(--blue);
}
.card:hover .numbers,
.card:hover .cardName,
.card:hover .iconBx {
color: var(--white);
}
</style>