<template>


  <Loading v-if="isloading"/>

    <v-container v-else>
        <v-row no-gutters>
        <v-col cols="12" md="4"
               xl="6"
         >
        <DashboardCard :title="items[0].title" :icon="items[0].icon" :valueNum="items[0].valueNum.toString()" />
        </v-col>
          <v-col cols="12" md="4"
                 xl="6"
          >
            <DashboardCard :title="items[1].title" :icon="items[1].icon" :valueNum="items[1].valueNum.toString()" />
          </v-col>
          <v-col cols="12" md="4"
                 xl="6"
          >
            <DashboardCard :title="items[2].title" :icon="items[2].icon" :valueNum="items[2].valueNum.toString()" />
          </v-col>
        </v-row>
        <BarChart :whatsapp="items[0].valueNum.toString()" :noification="items[1].valueNum.toString()" :sms="items[2].valueNum.toString()"/>
    </v-container>
</template>

<script>

import DashboardCard from '@/components/DashboardCard.vue';
import BarChart from '../components/BarChart.vue';
import Loading from '../components/Loading.vue';
import PushServicesSocket from '../services/PushServicesSocket.js';
    export default {
        
        components: {
            DashboardCard,
            BarChart,
          Loading,
    },
     async mounted() {
       this.isloading = true;

       this. dataItems = await PushServicesSocket.GetData("/balance/get");

       this. whatsappItems = await PushServicesSocket.GetData("/balance/whatsapp/get");
       if(this. dataItems)
         this.items[2].valueNum=parseFloat((this.dataItems.balance??"0").toFixed(2));
        if(this.whatsappItems.status)
        this.items[1].valueNum=this.whatsappItems.data.quota=="unlimited"?"0":this.whatsappItems.data.quota??"0";
       else
          this.items[1].valueNum="0";

       this.isloading = false;

      },


      data: () =>({

          isloading: false,
          dataItems: {},
        whatsappItems: {},
            items: [
                {

                    title: "الاشعارات",
                    valueNum: "0",
                    icon: "mdi-bell-outline"

                },
                {
                    title: "رسائل وتساب",
                    valueNum: "0",
                    icon: "mdi-whatsapp",
                },
                {
                    title: "رسائل SMS ريال",
                    valueNum: "0",
                    icon: "mdi-message-outline"
                },
            ]
        })
    }


    
</script>

<style scoped>

</style>