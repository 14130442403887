<template>
<div class="chartsBx">
                <div class="chart"> <canvas id="chart-1"></canvas> </div>
                <div class="chart"> <canvas id="chart-2"></canvas> </div>
            </div>
</template>
<script>

import Chart from 'chart.js'
export default {
  
  props: [ 'whatsapp','sms','noification'],
  data: ()=>({

  }),
  mounted(){
const ctx1 = document.getElementById("chart-1").getContext("2d");
const myChart = new Chart(ctx1, {
  type: "pie",
  data: {
    labels: [ "اشعارات","رسايل واتساب","رسايل SMS"],
    datasets: [
      {
        label: "الرسايل المرسلة",
        data: [this.noification,this.whatsapp,this.sms],
        backgroundColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
        ],
      },
    ],
  },
  options: {
    responsive: true,
  },
});

const ctx2 = document.getElementById("chart-2").getContext("2d");
const myChart2 = new Chart(ctx2, {
  type: "bar",
  data: {
    labels: [ "اشعارات","رسايل واتساب","رسايل SMS"],
  
    datasets: [
      {
        label: "",
        data: [this.noification,this.whatsapp,this.sms],
        backgroundColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 206, 86, 1)",
        ],
      },
    ],
  },
  options: {
    responsive: true,
  },
});
  },

}


</script>


<style>
/* ================== Charts JS ============== */
.chartsBx{

  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 55%;
  grid-gap: 30px;
}

.chartsBx .chart{
  position: relative;
  background: #fff;
  padding: 10px;
  width: 100%;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  border: 1px solid var(--blue);
}

@media (max-width: 991px) {
  .chartsBx{
    grid-template-columns: 1fr;
    height: auto;
  }
  
  }
</style>